import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import performanceStyles from "./performance.module.css";
import Section from "../common/Section.js";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { navigate } from "gatsby"; 

import PresidentImageLink from "../common/PresidentImageLink";

class Performance extends Component {
  render() {
    return (
      <Section name="president_performance" header="President Performance">
        <Row style={{ marginTop: "30px" }}>
          <Col className={performanceStyles.presidentGroup} md={4}>
            <PresidentImageLink name={"Joe Biden"} />
          </Col>
          <Col className={performanceStyles.presidentGroup} sm={4}>
            <PresidentImageLink name={"Donald Trump"} />
          </Col>
          <Col className={performanceStyles.presidentGroup} sm={4}>
            <PresidentImageLink name={"Barack Obama"} />
          </Col>
        </Row>
        <Row style={{ marginTop: "30px" }}>  
          <Col className={performanceStyles.presidentGroup} sm={4}>
            <PresidentImageLink name={"George W Bush"} />
          </Col>        
          <Col className={performanceStyles.presidentGroup} sm={4}>
            <PresidentImageLink name={"Bill Clinton"} />
          </Col>
          <Col className={performanceStyles.presidentGroup} sm={4}>
            <PresidentImageLink name={"George H.W. Bush"} />
          </Col>
        </Row>

        <div className={performanceStyles.moreTitle}>
          <Button className={performanceStyles.moreButton}
            onClick = { () => navigate("/presidents/") } 
          >
            See All Presidents
            <FontAwesomeIcon
              icon={faArrowAltCircleRight}
              style={{
                float: "right",
                color: "#FF6700",
                fontSize: "24px",
                backgroundColor: "white",
                borderRadius: "50px"
              }}
            />
          </Button>
        </div>
      </Section>
    );
  }
}

export default Performance;
