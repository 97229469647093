import React, { Component } from "react"
import { graphql } from "gatsby"

// Common components & Layout 
import Intro from "../components/intro/intro"
import Newsletter from "../components/newsletter/newsletter"
import Layout from "../layouts"
import Compare from "../components/compare/compare"
import Performance from "../components/performance/performance"
import Metrics from "../components/metrics/metrics"
import { Container, Row, Col } from "react-bootstrap";
import Section from  "../components/common/Section"; 

// embedded styles
import "../../static/styles/home.css"

// import "../../static/styles/global.css"
import homeStyles from './index.module.css';
import Latest  from "../components/latest/latest"
import { Learning } from "../components/learning/learning"
import SEO from "../components/common/SEO"

class Home extends Component {
  render() {
    const data = this.props.data
    const metrics = data.allLatestMetrics.nodes;
    
    return (
      <Layout>
        <SEO post={{
          title: "Facts First Home",
          description: "Facts First is a nonpartisan tool for measuring and comparing presidents across different metrics. No opinions, just facts."
        }} />
        <div id="homepage">
			<div className={homeStyles.homeHeader+' '+ ['page-hero']}>
			  <Container>
				<div className={homeStyles.title+' '+ ['page-title']}>The Only Zero Opinion Site For Presidential Performance</div>
			  </Container>
			</div>

			<Container>
			<Row><Col><Intro /></Col></Row>
			<Row className={'latest'}><Col><Latest /></Col></Row>
			<Row className={'news-letter'}><Col><Newsletter /></Col></Row>
			<Row className={'compare'}>
			  <Col xl={6} lg={12} md={12}><Compare /></Col>
			  <Col xl={6} lg={12} md={12}><Performance /></Col>
			</Row>
			<Row className={'metrics'}><Col><Metrics metrics={metrics} /></Col></Row>                
			<Row><Col><Learning /></Col></Row>
			<Row><Col><Newsletter /></Col></Row>
			</Container>
        </div>
      </Layout>
    )
  }
}

export default Home

export const query = graphql`
  {
    allLatestMetrics {
      nodes {
        id
        value
        totalGain
        percentGain
        name
        annualizedGain
        date
      }
    }
  }
`; 