import React, { Component } from "react"
import { Button, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import compareStyles from "./compare.module.css"
import Section from "../common/Section.js"
import { navigate } from "gatsby"; 

import trumpImage from "../../../static/assets/trump.jpg"
import obamaImage from "../../../static/assets/obama.jpg"
import bush2Image from "../../../static/assets/bush2.jpg"
import clintonImage from "../../../static/assets/clinton.jpg"
import reaganImage from "../../../static/assets/reagan.png"
import bidenImage from "../../../static/assets/biden.png"
// import { left } from "glamor";

class Compare extends Component {


    render() {
        return (
          <Section name="comparison" header="Compare Two Presidents">
            <Container>
              <a href="/comparison/joe-biden/donald-trump">
                <Row
                  className={
                    compareStyles.compareGroup + " " + ["compare-unit"]
                  }
                >
                  <Col className={compareStyles.presidentImageR}>
                    <img src={bidenImage} alt="" />
                  </Col>
                  <Col className={compareStyles.presidentName}>
                    Joe Biden
                  </Col>
                  <Col className={compareStyles.compareLink}> Compare</Col>
                  <Col className={compareStyles.presidentName}>
                    Donald Trump
                  </Col>
                  <Col
                    className={compareStyles.presidentImageD}
                    style={{ textAlign: "right" }}
                  >
                    <img src={trumpImage} alt="" />
                  </Col>
                </Row>
              </a>

              <a href="/comparison/joe-biden/barack-obama">
                <Row
                  className={
                    compareStyles.compareGroup + " " + ["compare-unit"]
                  }
                >
                  <Col className={compareStyles.presidentImageD}>
                    <img src={bidenImage} alt="" />
                  </Col>
                  <Col className={compareStyles.presidentName}>
                    Joe Biden
                  </Col>
                  <Col className={compareStyles.compareLink}>Compare</Col>
                  <Col className={compareStyles.presidentName}>
                    Barack Obama
                  </Col>
                  <Col
                    className={compareStyles.presidentImageD}
                    style={{ textAlign: "right" }}
                  >
                    <img src={obamaImage} alt="" />
                  </Col>
                </Row>
              </a>

              <a href="/comparison/donald-trump/barack-obama">
                <Row
                  className={
                    compareStyles.compareGroup + " " + ["compare-unit"]
                  }
                >
                  <Col className={compareStyles.presidentImageD}>
                    <img src={trumpImage} alt="" />
                  </Col>
                  <Col className={compareStyles.presidentName}>
                    Donald Trump
                  </Col>
                  <Col className={compareStyles.compareLink}>Compare</Col>
                  <Col className={compareStyles.presidentName}>
                    Barack Obama
                  </Col>
                  <Col
                    className={compareStyles.presidentImageR}
                    style={{ textAlign: "right" }}
                  >
                    <img src={obamaImage} alt="" />
                  </Col>
                </Row>
              </a>

              <a href="/comparison/barack-obama/bill-clinton">
                <Row
                  className={
                    compareStyles.compareGroup + " " + ["compare-unit"]
                  }
                >
                  <Col className={compareStyles.presidentImageR}>
                    <img src={obamaImage} alt="" />
                  </Col>
                  <Col className={compareStyles.presidentName}>
                    Barack Obama
                  </Col>
                  <Col className={compareStyles.compareLink}>Compare</Col>
                  <Col className={compareStyles.presidentName}>
                    Bill Clinton
                  </Col>
                  <Col
                    className={compareStyles.presidentImageR}
                    style={{ textAlign: "right" }}
                  >
                    <img src={clintonImage} alt="" />
                  </Col>
                </Row>
              </a>

              <Row>
                <Col className={compareStyles.moreTitle}>
                  <div>Or compare any combinations of presidents we track</div>
                  <Button className={compareStyles.moreButton}
                    onClick={ ()=> { navigate("/compare-presidents/") } }
                  >
                    Compare Two Presidents
                    <FontAwesomeIcon
                      width="0"
                      icon={faArrowAltCircleRight}
                      style={{
                        float: "right",
                        color: "#FF6700",
                        fontSize: "24px",
                        backgroundColor: "white",
                        borderRadius: "50px",
                      }}
                    />
                  </Button>
                </Col>
              </Row>
            </Container>
          </Section>
        );
    }




}

export default Compare; 

/*
                <div className={compareStyles.compareGroup}>
                    <div className={compareStyles.presidentImageR}><img src={trumpImage} /> Donald Trump</div>
                    <div className={compareStyles.compareLink}>Compare</div>
                    <div className={compareStyles.presidentImageD} style={{ textAlign: "right" }}>Barack Obama <img src={obamaImage} /></div>
                </div>

                <div className={compareStyles.compareGroup}>
                    <div className={compareStyles.presidentImageD}><img src={obamaImage} /> Barack Obama</div>
                    <div className={compareStyles.compareLink}>Compare</div>
                    <div className={compareStyles.presidentImageD} style={{ textAlign: "right" }}>Bill Clinton <img src={clintonImage} /></div>
                </div>

                <div className={compareStyles.compareGroup}>
                    <div className={compareStyles.presidentImageD}><img src={obamaImage} /> Barack Obama</div>
                    <div className={compareStyles.compareLink}>Compare</div>
                    <div className={compareStyles.presidentImageR} style={{ textAlign: "right" }}>George W Bush <img src={bush2Image} /></div>
                </div>

                <div className={compareStyles.compareGroup}>
                    <div className={compareStyles.presidentImageR}><img src={trumpImage} /> Donald Trump</div>
                    <div className={compareStyles.compareLink}>Compare</div>
                    <div className={compareStyles.presidentImageR} style={{ textAlign: "right" }}>Ronald Reagan <img src={reaganImage} /></div>
                </div>
*/
