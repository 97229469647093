import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { withCookies } from "react-cookie";
import introStyles from "./intro.module.css";
import Section from "../common/Section.js";

class Intro extends Component {
  closeIntro() {
    const { cookies } = this.props;
    cookies.set("close-intro", true, { path: "/" });
  }

  render() {
    const { cookies } = this.props;
    if (cookies.get("close-intro") === "true") return null;

    return (
      <div className={introStyles.intro + " " + ["intro-msg"]}>
        <FontAwesomeIcon
          width="0"
          className={introStyles.closeIntro}
          icon={faTimesCircle}
          onClick={() => this.closeIntro()}
        />
        <Section name="intro" css={introStyles.intro} />
      </div>
    );
  }
}
export default withCookies(Intro);
