import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col, Button } from "react-bootstrap"
import { SectionHeader } from "../common/section-header"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import Img from "gatsby-image"
import latestStyles from "./latest.module.css"
import { navigate } from "gatsby"

import parse from 'html-react-parser'; 

class Latest extends React.Component {

  constructor(props) {
    super(props); 

    this.css = this.props.css ? this.props.css : 'container'; 
    this.renderArticles = this.renderArticles.bind(this); 
  }

  renderArticles(articles) {

    let firstArticle = articles[0]; 
    let secArticle = articles[1]; 
    let thirdArticle = articles[2]; 

    return (
      <section id="latest-updates">
        <SectionHeader name="Latest Updates" />
        <Row className="container news first-row">
          <Col className="first-item">
            <label>
              News | {new Date(firstArticle.date).toISOString().slice(0, 10)}
            </label>
            <h2>
              <a href={firstArticle.path}>{parse(firstArticle.title)}</a>
            </h2>
            <div className="dek">{parse(firstArticle.excerpt)}</div>
          </Col>
          <Col className="second-item">
            <div>
              {firstArticle.acf.image && (
                <a href={firstArticle.path}>
                <Img
                  fluid={firstArticle.acf.image.localFile.childImageSharp.fluid}
                />
                </a>
              )}
            </div>
          </Col>
        </Row>
        <Row className="container news second-row">
          <Col className="third-item">
            <label>
              News | {new Date(secArticle.date).toISOString().slice(0, 10)}
            </label>
            <h2>
              <a href={secArticle.path}>{parse(secArticle.title)}</a>
            </h2>
            {secArticle.acf.image && (
               <a href={secArticle.path}>
              <Img
                fluid={secArticle.acf.image.localFile.childImageSharp.fluid}
                style={{ height: "250px", marginBottom: "5px" }}
              />
              </a>
            )}
            <div className="dek"> {parse(secArticle.excerpt)}</div>
          </Col>
          <Col className="fourth-item">
            <label>
              News | {new Date(thirdArticle.date).toISOString().slice(0, 10)}
            </label>
            <h2>
              <a href={thirdArticle.path}>{parse(thirdArticle.title)}</a>
            </h2>
            {thirdArticle.acf.image && (
               <a href={thirdArticle.path}>
              <Img
                fluid={thirdArticle.acf.image.localFile.childImageSharp.fluid}
                style={{ height: "250px", marginBottom: "5px" }}
              />
              </a>
            )}
            <div className="dek"> {parse(thirdArticle.excerpt)}</div>
          </Col>
        </Row>

        <Row>
          <Col className="more-button">
            <Button className={latestStyles.moreButton}
            onClick={ () => navigate("/articles") }
            >
              More Latest Updates
              <FontAwesomeIcon
                width="0"
                icon={faArrowAltCircleRight}
                style={{
                  float: "right",
                  color: "#FF6700",
                  fontSize: "24px",
                  backgroundColor: "white",
                  borderRadius: "50px",
                }}
              />
            </Button>
          </Col>
        </Row>
      </section>
    ); 
  }

  render() {
   return (
    <StaticQuery
      query={graphql`
      {
        allWordpressPost(limit: 3, sort: {order: DESC, fields: date}, filter: {categories: {elemMatch: {name: {eq: "News"}}}}) {
          nodes {
            content
            excerpt
            categories {
              name
            }
            date
            path
            slug
            title
            id
            acf {
              image {
                id
                source_url
                title
                caption
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth:600, maxHeight:400) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
      render={(data) => {
        const result = data.allWordpressPost.nodes; 

        if(!result) {
          console.error(`Could not find articles for this: ${this.props.name} was not found in graphql query for section, content could not be displayed.`); 
          return (
            <div/>
          ); 
        } else {
          return(this.renderArticles(result))
        }        
      }}
    />
  );
}  

};

Latest.propTypes = {
  css: PropTypes.string
}; 

export default Latest ; 